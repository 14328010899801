<template>
  <div>
    <PopupDialog :dialogOpen="dialog"/>
    <PopupDialog 
      :dialogOpen="deleteEventDialog"
      :dialogText="$t('event.dialogs.deleteText')"
      :dialogTitle="$t('event.dialogs.deleteTitle')"
      dialogType="info"
      @dialogOk="deleteEvent"
      @dialogCancel="deleteEventDialog=false"
    />
    <PopupDialog 
      :dialogOpen="unlinkUserDialog"
      :dialogText="$t('event.dialogs.unlinkText')"
      :dialogTitle="$t('event.dialogs.unlinkTitle')"
      dialogType="info"
      @dialogOk="unlinkUser"
      @dialogCancel="unlinkUserDialog=false"
    />
    <SnackBar :snackbarOpen="snackbar" :text="snackBarText" @snackBarClosed="snackbar=false"/>
    <v-alert v-if="error" type="error" outlined>
      {{ error }}
    </v-alert>
    <v-btn text color="primary" class="mx-0 mt-3 mb-5 plain" @click="back()">
      <v-icon class="mr-2">arrow_back</v-icon>
      <span class="text-none">{{ $t("event.back") }}</span>
    </v-btn>
    <div v-if="event.isActive">
      <div v-if="isCurrentOzEvent" class="mb-5">
        <v-btn text class="grey--text text--darken-2 text-none" @click="deleteEventDialog=true">
          <v-icon class="primary--text mr-2">delete</v-icon>
          <span class="text-none">{{ $t("event.delete") }}</span>
        </v-btn>
        <v-btn v-if="!editing && isInFuture" text class="grey--text text--darken-2 text-none" @click="changeEditing">
            <v-icon class="primary--text mr-2">edit</v-icon>
            <span>{{ $t("event.edit.title") }}</span>
        </v-btn>
        <v-btn text class="grey--text text--darken-2 text-none" @click="cancelEditing" v-if="editing">
            <v-icon class="primary--text mr-2">cancel</v-icon>
            <span>{{ $t("event.cancel") }}</span>
        </v-btn>
      </div>
      <v-card class="" flat style="border-radius: 20px">
            <v-card-title :style="{'background-color': 'var(--v-primary-base)', 'align-items': 'flex-end', 'height': heightTitle, 'font-size': $vuetify.breakpoint.smAndDown ? '1.5em' : '2.5vw'}" 
             :class="[$vuetify.breakpoint.smAndDown ? 'pl-5' : 'pl-10', 'pb-0']">
                <p :style="{'word-break': 'normal', 'line-height': '1.3em'}" class="white--text font-weight-bold">{{event.title}}</p>
            </v-card-title>
        <v-card-text :class="[$vuetify.breakpoint.smAndDown ? 'pa-7' : 'pa-10']">
    
        <!-- Hlavný content -->
        <div v-if="editing">
            <EventForm :inputEvent="eventChangeData" @submit="changeEditing" mode="change"/>
        </div>
        <div v-if="!editing">
            <v-row justify="center" class="mb-10">
              <v-col cols=12 md=9 :class="[$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title']">
                <div class="headline gre--text text--darken-2 mb-5 font-weight-medium">{{ $t("event.form.detail") }}: </div>
                <p :style="$vuetify.breakpoint.smAndDown ? 'line-height: 1.7rem;' : 'line-height: 2.2rem;'" 
                :class="['text-justify', 'font-weight-regular', $vuetify.breakpoint.smAndDown ? 'body-2' : '']" 
                v-html="addBreakLines(event.desc)"></p>
              </v-col>
            </v-row>
            <v-row justify="space-around" :class="[$vuetify.breakpoint.smAndDown ? 'px-0' : 'px-10']">
              <v-col cols=12 md=5 :class="[$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title']">
                  <div class="headline grey--text text--darken-2 mb-2 font-weight-medium">{{ $t("event.form.moreInfo") }}:</div>
                  <div class="font-weight-medium grey--text text--darken-2 mt-3">{{ $t("event.form.when") }}?</div>
                  <div class=" font-weight-regular">
                    <span v-if="event.type == 2 || event.type == 3">
                      {{ $t("event.form.theseDays") }}:
                      <WeekdaysDisplay :weekdays="event.weekdays" />
                    </span>
                    <v-icon class="primary--text mb-1 mr-2">date_range</v-icon>
                    <span v-if="event.type == 4">{{ $t("offer.longTerm") }}</span>
                    <span v-else-if="event.type == 2 || event.type == 3">{{ $t("event.form.next") }} {{ formatDate(event.nextEventDate) }} ({{getWeekDay(event.nextEventDate)}})</span>
                    <span v-else-if="event.endDate && event.endDate != event.date && event.type != 1 && event.type != 2">{{formatDate(event.nextEventDate)}}, {{ event.time }} - {{formatDate(event.endDate)}}, {{ event.endTime }}</span>
                    <span v-else-if="event.endDate && event.endDate != event.date">{{formatDate(event.nextEventDate)}} - {{formatDate(event.endDate)}}</span>
                    <span v-else>{{formatDate(event.nextEventDate)}} ({{getWeekDay(event.nextEventDate)}})</span>
                  </div>
                  <div v-if="event.type != 4 && !(event.endDate && event.endDate != event.date && event.type != 1 && event.type != 2)">
                    <v-icon class="primary--text mb-1 mr-2">access_time</v-icon>
                    <span class="grey--text text--darken-2 mb-1 font-weight-regular">
                      <span v-if="event.type == 1 || event.type == 2">{{ $t("offer.allDay") }}</span>
                      <span v-else>{{ event.time }} - {{ event.endTime }}</span>
                    </span>
                  </div>
                  <div class="font-weight-medium grey--text text--darken-2 mt-3">{{ $t("event.form.where") }}?</div>
                  <div v-if="event.place">
                    <v-icon class="primary--text mb-1 mr-2">place</v-icon>
                    <span class="grey--text text--darken-2 mb-1 font-weight-regular">{{ event.place }}</span>
                  </div>
                  <div v-else>
                    <v-icon class="primary--text mb-1 mr-2">near_me</v-icon>
                    <span class="grey--text text--darken-2 mb-1 font-weight-regular">{{ findRegionNameByCode(event.region) }}</span>
                  </div>
                  <div class="font-weight-medium grey--text text--darken-2 mt-3">{{ $t("event.form.howMany") }}?</div>
                  <div class="grey--text text--darken-2 mb-1 font-weight-regular">{{ $t("event.form.freePlaces") }}: {{event.demand - event.linkedUsers}}</div>
                  <div class="grey--text text--darken-2 mb-1 font-weight-regular">{{ $t("offer.joined") }}: {{ event.linkedUsers }}</div>
              </v-col>
              <v-col cols=12 md=7 :class="[$vuetify.breakpoint.smAndDown ? 'subtitle-1': 'title']">               
                <v-skeleton-loader
                  :loading="loadingOZ"
                  height="94"
                  type="list-item-three-line"
                >
                  <div class="headline gre--text text--darken-2 mb-2 font-weight-medium mb-5">{{ $t("event.form.organizer") }}: </div>
                  <v-hover v-slot:default="{ hover }" style="cursor: pointer;">
                    <v-card flat class="pa-3" :color="hover ? 'grey lighten-3' : 'white'" @click="$router.push({name: 'WebAppProfile', params: {id: oz.ID}})">
                      <div class="grey--text text--darken-2 mb-1 font-weight-regular"><v-icon class="primary--text mb-1 mr-2">business</v-icon>{{ oz.title }}</div>
                      <div v-if="oz.telephone" class="grey--text text--darken-2 mb-1 font-weight-regular"><v-icon class="primary--text mb-1 mr-2">local_phone</v-icon>{{ oz.telephone }}</div>
                      <div class="grey--text text--darken-2 mb-1 font-weight-regular"><v-icon class="primary--text mb-1 mr-2">email</v-icon>{{ oz.email }}</div>
                    </v-card>
                  </v-hover>
                </v-skeleton-loader>
                <div v-if="$store.state.loggedInFlag == '2'" class="font-weight-regular mt-5">{{ $t("event.form.challenge") }}</div>
                <div class="font-weight-regular mt-5" v-if="event.isProtected">{{ $t("event.form.typeTitle") }}
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <span class="primary--text" v-on="on" style="cursor: pointer;">{{ $t("event.form.protected") }}</span>
                    </template>
                    <div style="width: 200px;">{{ $t("event.form.protectedTooltip") }}</div>
                  </v-tooltip>
                </div>
                <div v-if="publicMode">
                  <v-row>
                    <v-col cols=12 lg=3>
                      <v-btn disabled depressed color="primary" class="mx-0 px-5">{{ $t("event.join.title") }}</v-btn>
                    </v-col>
                    <v-col cols=12 lg=9 align-self="center">
                      <span class="body-1">{{ $t("event.join.text") }} <router-link :to="{name: 'login', query: {nextUrl: this.$route.fullPath}}">{{ $t("event.join.login") }}</router-link></span>
                    </v-col>
                  </v-row>
                </div>
                <div v-else-if="$store.state.loggedInFlag == '2'">
                  <v-btn v-if="isLinked" @click="unlinkUserAsk(event.ID_offer, 1)" depressed color="primary" class="mx-0 mt-5 px-5">{{ $t("event.cancelRequest.title") }}</v-btn>
                  <v-btn v-else-if="isRequested" @click="unlinkUserAsk(event.ID_offer, 0)" depressed color="primary" class="mx-0 mt-5 px-5">{{ $t("event.cancelRequest.text") }}</v-btn>
                  <v-btn v-else-if="event.linkedUsers >= event.demand" depressed color="secondary" class="mx-0 mt-5 px-5">{{ $t("event.cancelRequest.full") }}</v-btn>
                  <v-btn v-else @click="linkUser(event.ID_offer)" depressed color="primary" class="mx-0 mt-5 px-5">{{ $t("event.cancelRequest.join") }}</v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="event.photos && event.photos.length" justify="center" class="mb-5">
              <v-col cols=12 md=10>
                <div class="headline gre--text text--darken-2 mb-2 font-weight-medium mb-5">{{ $t("event.gallery") }}</div>
                <v-carousel :height="$vuetify.breakpoint.smAndDown ? '200' : '500'">
                <v-carousel-item
                  v-for="(src,i) in event.photos"
                  :key="i"
                >
                <v-img :src='getImgSrc(src)' :height="$vuetify.breakpoint.smAndDown ? '200' : '500'">
                  <template v-slot:placeholder>
                    <v-row class="fill-height" justify="center" align-content="center">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                </v-carousel-item>
              </v-carousel>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col v-if="event.latitude" cols=12 md=8>
                <div class="headline gre--text text--darken-2 mb-2 font-weight-medium mb-5">{{ $t("event.map") }}</div>
                <Map 
                  :events="[{...event, ...{isLinked: this.isLinked, isRequested: this.isRequested}}]"
                  :center="{ lat: event.latitude, lng: event.longitude }"
                  :height=380
                  :showTooltipMoreInfo=false
                  />
              </v-col>
            </v-row>
            <div v-if="isCurrentOzEvent">
              <LinkedUsersDisplay 
                 :users="linkedUsers" 
                 :isActiveFilter="true" 
                 :titleComponent="$t('linkedUsers.singleOffer')"
              />
            </div>
          
        </div>
        </v-card-text>
      </v-card>
    </div>
    <div v-else-if="event.isActive===0">{{$t("offer.deleted")}}</div>
  </div>
</template>

<script>
  import axios from 'axios'
  import utilitiesMixin from '@/mixins/utilitiesMixin.js'
  import inputRulesMixin from '@/mixins/inputRulesMixin.js'
  import LinkedUsersDisplay from '@/components/dashboard/LinkedUsersDisplay'
  import PopupDialog from '@/components/partials/PopupDialog'
  import Map from '@/components/MapLeaflet'
  import SnackBar from '@/components/partials/SnackBar'
  import WeekdaysDisplay from '@/components/partials/WeekdaysDisplay'
  import EventForm from '@/components/events/EventForm'
  export default {
      name: "WebAppEventDetail",
      mixins: [utilitiesMixin, inputRulesMixin],
      components: {LinkedUsersDisplay, PopupDialog, Map, SnackBar, WeekdaysDisplay, EventForm},
      data() {
          return {
              endTime: '',
              error: '',
              dialog: false,
              event: {},
              oz: {},
              isLinked: false,
              isRequested: false,
              loadingOZ: true,
              isCurrentOzEvent: false,
              linkedUsers: [],
              editing: false,
              eventChangeData: {},
              publicMode: false,
              deleteEventDialog: false,
              placeCoords: null,
              snackBarText: '',
              snackbar: false,
              unlinkUserData: null,
              unlinkUserDialog: false,
              backRouteName: 'WebAppEventHome',
              everyDay: false
          }
      },
      computed: {
          heightTitle: function() {
              if (this.$vuetify.breakpoint.smAndDown) {
                  return '120px'
              }
              else {
                  return '180px'
              }
          },
          isInFuture: function() {
            if (this.event.type == 0) {
                return new Date(`${this.event.endDate}T${this.event.endTime}:00`) >= Date.now()
            } else if (this.event.type == 1) {
                return new Date(`${this.event.endDate}T24:00:00`) >= Date.now()
            } else if (this.event.type == 2) {
                return new Date(`${this.event.nextEventDate}T24:00:00`) >= Date.now() // should always be true
            } else if (this.event.type == 3) {
                return new Date(`${this.event.nextEventDate}T${this.event.endTime}:00`) >= Date.now() // should always be true
            } else {
                return true
            }
          }
      },
      methods: {
        back() {
            this.$router.push({name: this.backRouteName})
        },

        // Metody na naplnenie informacneho obsahu eventu
        getEventContent() {
            axios.post('/get_offers_info', {ID_offers: [this.$route.params.id]})
            .then(response => {
                if (response.data.info.length) {
                    document.title = response.data.info[0].title
                    this.event = response.data.info[0];

                    // filter not localized tags
                    (this.event.tags || []).forEach((tag, indx) => {
                      if(!this.$store.state.tags[tag]){
                        const tags = this.$i18n.getLocaleMessage('sk-SK').tags;
                        const key = Object.keys(tags).find(t => tags[t].name === tag);  // english tag name

                        this.event.tags[indx] = key;
                      }
                    });
                    
                    this.getOzContent()
                } else {
                    this.$router.push({name: 'WebAppEventHome'})
                }
            })
            .catch(() => {
                this.dialog = true
            })
        },
        getOzContent() {
            axios.post('/get_oz_info', {'ID_OZ': this.event.ID_OZ})
            .then(response => {
                this.oz = response.data
                this.loadingOZ = false
            })
        },

        // Metody na funkcionalitu buttonov pridat sa a odobrat sa
        linked() {
          axios.get('/auth/get_linked_offers')
          .then(response => {
              const links = response.data.links
              for (let i=0;i<links.length;i++) {
                  const link = links[i]
                  if (link.isActive && link.ID_offer == this.$route.params.id) {
                      this.isLinked = true
                      break
                  }
              }
              const requests = response.data.requests
              for (let i=0;i<requests.length;i++) {
                  const request = requests[i]
                  if (request.ID_offer == this.$route.params.id) {
                      this.isRequested = true
                      break
                  }
              }
          })
          .catch(error => {
              if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                  this.$router.push({ name: 'logout' })
              } else {
                  this.dialog = true
              }
          })
        },
        linkUser(id) {
          axios.post('/auth/link', {ID_offer: parseInt(id)})
          .then((response) => {
              if (this.event.isProtected) {
                  this.snackBarText = this.$t("event.join.requestSent")
              }
              else {
                  this.snackBarText = this.$t("event.join.successful")
              }
              this.snackbar = true
              this.error = ''
              if (response.data.message.includes('request')) {
                  this.isRequested = true
              } else {
                  this.isLinked = true
                  this.event.linkedUsers += 1;
              }
          }).catch(error =>{
            if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                this.$router.push({ name: 'logout' })
            } else {
                if (error.response && error.response.data && error.response.data.message) {
                    const msg =  error.response.data.message
                    if (msg === "Offer does not exist.") {
                        this.error = this.$t("event.join.errors.notExists")
                        window.scrollTo(0,0)
                    } else if (msg === "You are already linked to the offer." || msg === "You have already requested to be linked to the offer.") {
                        this.error = this.$t("event.join.errors.alreadyJoined")
                        window.scrollTo(0,0)
                    } else {
                        this.dialog = true
                    }
                } else {
                    this.dialog = true
                }
            }
          })
        },
        unlinkUser() {
            this.unlinkUserDialog = false
            let id = this.unlinkUserData.id
            let count = this.unlinkUserData.count
            axios.post('/auth/unlink', {ID_offer: parseInt(id)})
            .then(() => {
                this.snackBarText = this.$t("event.cancelRequest.successful")
                this.snackbar = true
                this.isLinked = false
                this.isRequested = false
            }).catch(error => {
                if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                    this.$router.push({ name: 'logout' })
                } else {
                    this.dialog = true
                }
            })
            this.event.linkedUsers -= count;
        },
        unlinkUserAsk(id, count) {
            this.unlinkUserData = {id: id, count: count}
            this.unlinkUserDialog = true
        },
        // Metody na zistenie ci dany event patri prihlasenemu OZku
        getIsCurrentOzEvent() {
          if (this.$store.state.loggedInFlag == '1') {
            axios.get('/auth/oz_get_offers')
            .then(response => {
                if (response.data.offers.includes(parseInt(this.$route.params.id))) {
                    this.isCurrentOzEvent = true
                    this.getLinkedUsers()
                }
                else {
                    this.isCurrentOzEvent = false
                }
            })
            .catch(error => {
                if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                    this.$router.push({ name: 'logout' })
                } else {
                    this.dialog = true
                }
            })
          }
          else {
              this.isCurrentOzEvent = false
          }
        },

        // Metody na ziskanie dat o evente ak dany event patri prihlasenemu OZku
        getLinkedUsers() {
          axios.get('/auth/oz_get_links')
          .then(response => {
              response.data.links.forEach(link => {
                  if (link.ID_offer == this.$route.params.id) {
                      this.linkedUsers.push({
                          name: link.name,
                          events: [
                            {
                              ID_offer: this.$route.params.id,
                              isActive: link.isActive,
                              worked: link.worked
                            }
                          ],
                          ID_user: link.ID_user
                      })
                  }
              })
          })
          .catch(error => {
              if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                  this.$router.push({ name: 'logout' })
              } else {
                  this.dialog = true
              }
          })
        },

        //Metody na funkciu editovania eventu
        changeEditing(eventData = null) {
            if (!this.editing) {
                this.editing = true
                this.eventChangeData.title = this.event.title
                this.eventChangeData.desc = this.event.desc
                this.eventChangeData.duration = this.event.duration
                this.eventChangeData.demand = this.event.demand
                this.eventChangeData.place = this.event.place
                this.eventChangeData.tags = this.event.tags
                this.eventChangeData.region = this.findRegionNameByCode(this.event.region)
                this.eventChangeData.isProtected = this.event.isProtected
                this.eventChangeData.weekdays = []
                this.eventChangeData.date = null
                this.eventChangeData.endDate = null
                this.eventChangeData.time = null
                this.eventChangeData.endTime = null
                this.eventChangeData.fullDay = null
                this.eventChangeData.timeType = null
                if (this.event.type == 0 || this.event.type == 1) {
                    this.eventChangeData.date = this.event.date
                    if (this.event.endDate == this.event.date) {
                        this.eventChangeData.timeType = 0
                        this.event.timeType = 0
                    }
                    else {
                        this.eventChangeData.endDate = this.event.endDate
                        this.eventChangeData.timeType = 1
                        this.event.timeType = 1
                    }
                }
                else if (this.event.type == 2 || this.event.type == 3) {
                    this.eventChangeData.weekdays = this.parseWeekdaysBack(this.event.weekdays)
                    this.eventChangeData.date = null
                    this.eventChangeData.timeType = 2
                    this.event.timeType = 2
                }
                else if (this.event.type == 4) {
                    this.eventChangeData.timeType = 3
                    this.event.timeType = 3
                }
                
                if (this.event.type == 1 || this.event.type == 2) {
                    this.eventChangeData.fullDay = true
                }
                else {
                    this.eventChangeData.time = this.event.time
                    this.eventChangeData.endTime = this.event.endTime
                }
            } else {
                this.editing = false
                let data = this.processChangeData(eventData, this.event)
                const keys = Object.keys(data)
                if (keys.length > 0) {
                    if (eventData.timeType == 0) {
                        if (keys.includes('date')) {
                            data['endDate'] = data.date
                        }
                    }
                    if (keys.includes('place')) {
                        if (data['place'] == "") {
                            data['latitude'] = this.$store.state.regions[eventData.region].location.lat
                            data['longitude'] = this.$store.state.regions[eventData.region].location.lng
                        }
                        else if (this.placeCoords != null) {
                            data['latitude'] = this.placeCoords.lat
                            data['longitude'] = this.placeCoords.lng
                        }
                    }
                    if (keys.includes('type')) {
                        if (data.type == 0) {
                            data['date'] = eventData.date
                            if (eventData.endDate) {
                                data['endDate'] = eventData.endDate
                            } else {
                                data['endDate'] = eventData.date
                            }
                            data['time'] = eventData.time
                            data['endTime'] = eventData.endTime
                        }
                        else if (data.type == 1) {
                            data['date'] = eventData.date
                            if (eventData.endDate) {
                                data['endDate'] = eventData.endDate
                            } else {
                                data['endDate'] = eventData.date
                            }
                        }
                        else if (data.type == 2) {
                            data['weekdays'] = this.parseWeekdays(eventData.weekdays)
                            data['time'] = eventData.time
                            data['endTime'] = eventData.endTime
                        }
                        else if (data.type == 3) {
                            data['weekdays'] = this.parseWeekdays(eventData.weekdays)
                        }
                    }
                    data['ID_offer'] = this.event.ID_offer
                    axios.post('/auth/change_offer_info', data)
                    .then(() => {
                        window.scrollTo(0,0)
                        this.error = ''
                        this.snackBarText = this.$t("event.edit.successful")
                        this.snackbar = true
                        this.getEventContent()
                    })
                    .catch(error => {
                        if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                            this.$router.push({ name: 'logout' })
                        } else {
                            if (error.response && error.response.data && error.response.data.message) {
                                const msg =  error.response.data.message
                                if (msg === "Offer does not exist") {
                                    this.error = this.$t("event.edit.errors.notExists")
                                    window.scrollTo(0,0)
                                } else if (msg === "No offer specified" || msg === "No data to change specified") {
                                    this.error = this.$t("event.edit.errors.notSpecified")
                                    window.scrollTo(0,0)
                                } else if (msg === "You do not own the offer, access denied") {
                                    this.error = this.$t("event.edit.errors.notOwn")
                                    window.scrollTo(0,0)
                                } else if (msg.includes('event ends before it starts')){
                                    this.error = this.$t("event.edit.errors.time")
                                    window.scrollTo(0,0)
                                } else if (msg.includes("INVALID DATA")) {
                                    this.error = this.$t("event.edit.errors.invalidData")
                                    window.scrollTo(0,0)
                                } else {
                                    this.dialog = true
                                }
                            } else {
                                this.dialog = true
                            }
                        }
                    })
                }
            }
        },
        cancelEditing() {
            this.editing = false
            this.eventChangeData = {}
            this.error = ''
        },
        processChangeData(changeData, previousData) {
            let toChange = {}
            Object.keys(changeData).forEach(
                key => {
                    if (key == 'fullDay' || key == 'timeType') {
                      let type = -1
                      if (changeData.timeType == 0 || changeData.timeType == 1) {
                          if (changeData.fullDay) {
                              type = 1
                          }
                          else {
                              type = 0
                          }                        
                      }
                      else if (changeData.timeType == 2) {
                          if (changeData.fullDay) {
                              type = 2
                          }
                          else {
                              type = 3
                          }
                      }
                      else if (changeData.timeType == 3) {
                          type = 4
                      }
                      if (type != previousData.type || changeData.timeType != previousData.timeType) {
                          if (changeData.timeType == 0) {
                              changeData.endDate = null
                          }
                          toChange.type = type
                      }
                    }
                    else {
                        let data = previousData[key]
                        let changedData = changeData[key]
                        if (key == 'weekdays') {
                            changedData = this.parseWeekdays(changedData)
                            if (data == null) {
                                data = "0000000"
                            }
                        }
                        if (key == 'region') {
                            changedData = this.$store.state.regions[changedData].code
                        }
                        if (key == 'endDate' && changeData.timeType != 1) {
                            data = changedData
                        }
                        if (data != changedData) {
                            if (key == 'isProtected') {
                                toChange[key] = changedData ? 1 : 0
                            }
                            else {
                                toChange[key] = key == 'demand' ? parseInt(changedData) : changedData
                            }
                        }
                    }
                }
            )
            return toChange
        },
        deleteEvent () {
            axios.post('/auth/change_offer_info', {ID_offer: this.event.ID_offer, isActive: 0})
            .then(() => {
                this.error = ''
                this.back()
            })
            .catch(error => {
                if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                    this.$router.push({ name: 'logout' })
                } else {
                    this.dialog = true
                }
            })
        },
        setBackRoute(name) {
            this.backRouteName = name
        }
      },
      beforeRouteEnter (to, from, next) {
          if (['WebAppHome', 'WebAppDashboard', 'landing', 'WebAppMap'].includes(from.name)) {
              next(vm => vm.setBackRoute(from.name))
          } else {
              next(vm => vm.setBackRoute('WebAppEventHome'))
          }
      },
      async created() {
          if (!this.$store.getters.loggedIn) {
              this.publicMode = true
          }
          if (!this.publicMode) {
              this.linked()
              this.getIsCurrentOzEvent()
          }
          this.getEventContent()
      }
  }
</script>

<style lang="scss" scoped>
</style>
