<template>
  <div :style="`height: ${height}px`">
    <l-map
      :style="`height: ${height}px`"
      :zoom="zoom"
      :center="center"
      class="map-container rounded-lg"
      ref="mapRef"
    >
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-marker
        v-for="event in eventsWithCoordinates(events)"
        :key="event.ID_offer"
        :lat-lng="[event.latitude, event.longitude]"
        @click="markerClick(event.ID_offer)"
      >
        <l-icon
          :icon-size="[32, 32]"
          :icon-anchor="[16, 32]"
          :icon-url="getIconUrl(event)"
          :icon-shadow-url="defaultMarkerShadow"
        />
        <l-tooltip
          class="map-tooltip"
          :options="{
            offset: [0, -32],
            direction: 'top',
            className: 'map-tooltip',
          }"
        >
          <v-card
            elevation="0"
            min-width="300"
            color="var(--v-accent-lighten4)"
          >
            <v-img v-if="false" height="150" :src="event.image" />
            <v-card-title>{{ event.title }}</v-card-title>
            <v-card-text v-if="showTooltipMoreInfo">{{
              $t("map.marker.moreInfo")
            }}</v-card-text>
            <v-chip
              class="ml-3 mb-3"
              small
              :color="
                event.isLinked
                  ? 'var(--v-success-lighten3)'
                  : event.isRequested
                  ? 'var(--v-warning-lighten3)'
                  : 'var(--v-error-lighten3)'
              "
            >
              {{
                event.isLinked
                  ? $t("map.marker.tag.linked")
                  : event.isRequested
                  ? $t("map.marker.tag.requested")
                  : $t("map.marker.tag.free")
              }}
            </v-chip>
          </v-card>
        </l-tooltip>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
//import L from "leaflet";
import { LMap, LTileLayer, LMarker, LIcon, LTooltip } from "vue2-leaflet";
export default {
  name: "LeafletMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LTooltip,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
    center: {
      type: Object,
      default: () => {
        return { lat: 48.770866, lng: 19.642608 };
      },
    },
    height: {
      type: Number,
      default: 600,
    },
    zoom: {
      type: Number,
      default: 13,
    },
    showTooltipMoreInfo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      defaultMarkerShadow:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAQAAAACach9AAACMUlEQVR4Ae3ShY7jQBAE0Aoz/f9/HTMzhg1zrdKUrJbdx+Kd2nD8VNudfsL/Th///dyQN2TH6f3y/BGpC379rV+S+qqetBOxImNQXL8JCAr2V4iMQXHGNJxeCfZXhSRBcQMfvkOWUdtfzlLgAENmZDcmo2TVmt8OSM2eXxBp3DjHSMFutqS7SbmemzBiR+xpKCNUIRkdkkYxhAkyGoBvyQFEJEefwSmmvBfJuJ6aKqKWnAkvGZOaZXTUgFqYULWNSHUckZuR1HIIimUExutRxwzOLROIG4vKmCKQt364mIlhSyzAf1m9lHZHJZrlAOMMztRRiKimp/rpdJDc9Awry5xTZCte7FHtuS8wJgeYGrex28xNTd086Dik7vUMscQOa8y4DoGtCCSkAKlNwpgNtphjrC6MIHUkR6YWxxs6Sc5xqn222mmCRFzIt8lEdKx+ikCtg91qS2WpwVfBelJCiQJwvzixfI9cxZQWgiSJelKnwBElKYtDOb2MFbhmUigbReQBV0Cg4+qMXSxXSyGUn4UbF8l+7qdSGnTC0XLCmahIgUHLhLOhpVCtw4CzYXvLQWQbJNmxoCsOKAxSgBJno75avolkRw8iIAFcsdc02e9iyCd8tHwmeSSoKTowIgvscSGZUOA7PuCN5b2BX9mQM7S0wYhMNU74zgsPBj3HU7wguAfnxxjFQGBE6pwN+GjME9zHY7zGp8wVxMShYX9NXvEWD3HbwJf4giO4CFIQxXScH1/TM+04kkBiAAAAAElFTkSuQmCC",
    };
  },
  methods: {
    eventsWithCoordinates: (events) =>
      events.filter((e) => !!e.longitude && !!e.latitude),
    getIconUrl: (event) =>
      event.isLinked
        ? "/assets/img/icons/map/map-marker-green-solid.svg"
        : event.isRequested
        ? "/assets/img/icons/map/map-marker-yellow-solid.svg"
        : "/assets/img/icons/map/map-marker-red-solid.svg",
    markerClick(ID_offer) {
      this.$router.push({
        name: "WebAppEventDetail",
        params: { id: ID_offer },
      });
    },
    created() {},
  },
};
</script>
<style>
.map-container {
  z-index: 1;
}
.map-tooltip {
  background-color: var(--v-accent-lighten4) !important;
  border-radius: 0.75rem !important;
}
</style>