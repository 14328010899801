var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(("height: " + _vm.height + "px"))},[_c('l-map',{ref:"mapRef",staticClass:"map-container rounded-lg",style:(("height: " + _vm.height + "px")),attrs:{"zoom":_vm.zoom,"center":_vm.center}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),_vm._l((_vm.eventsWithCoordinates(_vm.events)),function(event){return _c('l-marker',{key:event.ID_offer,attrs:{"lat-lng":[event.latitude, event.longitude]},on:{"click":function($event){return _vm.markerClick(event.ID_offer)}}},[_c('l-icon',{attrs:{"icon-size":[32, 32],"icon-anchor":[16, 32],"icon-url":_vm.getIconUrl(event),"icon-shadow-url":_vm.defaultMarkerShadow}}),_c('l-tooltip',{staticClass:"map-tooltip",attrs:{"options":{
          offset: [0, -32],
          direction: 'top',
          className: 'map-tooltip',
        }}},[_c('v-card',{attrs:{"elevation":"0","min-width":"300","color":"var(--v-accent-lighten4)"}},[(false)?_c('v-img',{attrs:{"height":"150","src":event.image}}):_vm._e(),_c('v-card-title',[_vm._v(_vm._s(event.title))]),(_vm.showTooltipMoreInfo)?_c('v-card-text',[_vm._v(_vm._s(_vm.$t("map.marker.moreInfo")))]):_vm._e(),_c('v-chip',{staticClass:"ml-3 mb-3",attrs:{"small":"","color":event.isLinked
                ? 'var(--v-success-lighten3)'
                : event.isRequested
                ? 'var(--v-warning-lighten3)'
                : 'var(--v-error-lighten3)'}},[_vm._v(" "+_vm._s(event.isLinked ? _vm.$t("map.marker.tag.linked") : event.isRequested ? _vm.$t("map.marker.tag.requested") : _vm.$t("map.marker.tag.free"))+" ")])],1)],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }