<template>
  <div>
    <v-alert v-if="error" type="error" outlined>
      {{ error }}
    </v-alert>
    <v-form class="px-3" ref="createForm" @submit.prevent="submitClick">
      <div class="headline grey--text text--darken-2 mb-5">
        {{ $t("eventForm.basicInformation") }}
      </div>
      <InputField
        :label="$t('eventForm.offerTitle')"
        icon="event_note"
        rules="maxLength80"
        v-model="eventData.title"
        :inputValue="eventData.title"
      />
      <InputField
        :label="$t('eventForm.offerDescription')"
        icon="edit"
        rules="maxLength1000"
        v-model="eventData.desc"
        :inputValue="eventData.desc"
        inputType="textarea"
      />
      <InputField
        v-if="mode == 'create'"
        :label="$t('eventForm.offerType')"
        icon="local_offer"
        rules="required"
        v-model="eventType"
        inputType="select"
        :items="eventPresets"
      />
      <InputField
        v-if="customTags"
        icon="local_offer"
        rules="required"
        v-model="eventData.tags"
        inputType="selectTags"
        :inputValue="eventData.tags ? Array.from(eventData.tags) : []"
        :label="$t('eventForm.offerTags') + ':'"
      />
      <div v-else-if="eventData.tags.length > 0">
        <span class="mr-3">{{ $t("eventForm.offerTags") }}:</span
        ><Tag v-for="(tag, index) in eventData.tags" :key="index" :name="tag" />
      </div>
      <div v-if="customTags || eventData.tags.length > 0" class="mb-5"></div>
      <InputField
        :label="$t('eventForm.offerFreePlaces')"
        icon="person"
        rules="isPositiveNumber"
        v-model="eventData.demand"
        :inputValue="eventData.demand"
      />
      <div class="headline grey--text text--darken-2 mb-5">
        {{ $t("eventForm.offerPlace") }}
      </div>
      <div class="subtitle-1 grey--text text--darken-2 mb-5">
        {{ $t("eventForm.offerPlaceTooltip") }}
      </div>
      <InputGeosearch
        :label="$t('eventForm.offerAddress')"
        icon="place"
        rules="None"
        @input="processAutoCompleteData"
        :inputValue="eventData.place"
      />
      <InputField
        class="mt-3"
        :label="$t('eventForm.offerRegion')"
        icon="near_me"
        v-model="eventData.region"
        rules="required"
        inputType="regionAutocomplete"
        :inputValue="eventData.region"
      />
      <div class="headline grey--text text--darken-2 mt-5 mb-3">
        {{ $t("eventForm.offerTime.title") }}
      </div>
      <div class="grey--text title font-weight-regular">
        {{ $t("eventForm.offerTime.subtitle") }}:
      </div>
      <v-radio-group v-model="eventData.timeType">
        <v-radio
          :label="$t('eventForm.offerTime.oneDay')"
          :value="0"
          class="mb-0 pa-0"
        ></v-radio>
        <v-row class="mb-1"
          ><v-col cols="12" md="8" class="pl-10">
            <span>{{ $t("eventForm.offerTime.oneDayDescription") }}</span>
          </v-col></v-row
        >
        <v-radio
          :label="$t('eventForm.offerTime.multiDay')"
          :value="1"
          class="mb-0 pa-0"
        ></v-radio>
        <v-row class="mb-1"
          ><v-col cols="12" md="8" class="pl-10">
            <span>{{ $t("eventForm.offerTime.multiDayDescription") }}</span>
          </v-col></v-row
        >
        <v-radio
          :label="$t('eventForm.offerTime.repeating')"
          :value="2"
          class="mb-0 pa-0"
        ></v-radio>
        <v-row class="mb-1"
          ><v-col cols="12" md="8" class="pl-10">
            <span>{{ $t("eventForm.offerTime.repeatingDescription") }}</span>
          </v-col></v-row
        >
        <v-radio
          :label="$t('eventForm.offerTime.longTerm')"
          :value="3"
          class="mb-0 pa-0"
        ></v-radio>
        <v-row class="mb-1"
          ><v-col cols="12" md="8" class="pl-10">
            <span>{{ $t("eventForm.offerTime.longTermDescription") }}</span>
          </v-col></v-row
        >
      </v-radio-group>
      <div v-if="eventData.timeType == 0">
        <InputField
          :label="$t('eventForm.offerTime.date')"
          icon="date_range"
          :rules="startDateDisabled ? 'None' : 'dateTestFuture'"
          v-model="eventData.date"
          :inputValue="eventData.date"
          inputType="date"
          :disabled="startDateDisabled"
        />
      </div>
      <div v-if="eventData.timeType == 1">
        <InputField
          :label="$t('eventForm.offerTime.startDate')"
          icon="date_range"
          :rules="startDateDisabled ? 'None' : 'dateTestFuture'"
          v-model="eventData.date"
          :inputValue="eventData.date"
          inputType="date"
          :disabled="startDateDisabled"
        />
        <InputField
          :label="$t('eventForm.offerTime.endDate')"
          icon="date_range"
          rules="dateTestMore"
          v-model="eventData.endDate"
          :inputValue="eventData.endDate"
          inputType="date"
          :inputRulesData="eventData.date"
        />
      </div>
      <div v-if="eventData.timeType == 2" class="mb-5">
        <div class="grey--text title font-weight-regular">
          {{ $t("eventForm.offerTime.chooseDays") }}:
        </div>
        <v-row>
          <v-col cols="auto" class="py-0">
            <WeekdaysDisplay
              :inputDisplay="true"
              :rules="true"
              @input="
                (value) => {
                  eventData.weekdays = value;
                  value.length < 7 ? (everyDay = false) : (everyDay = true);
                }
              "
              v-model="eventData.weekdays"
              :weekdays="parseWeekdays(eventData.weekdays)"
              outputForm="form"
            />
            <v-checkbox
              class="ma-0 mb-10"
              v-model="everyDay"
              @change="everyDayClick"
              :label="$t('eventForm.offerTime.everyDay')"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </div>
      <div v-if="eventData.timeType != 3">
        <v-divider class="mb-8"></v-divider>
        <InputField
          :label="$t('eventForm.offerTime.startTime')"
          icon="access_time"
          :rules="
            eventData.fullDay
              ? 'None'
              : eventData.timeType == 2 || startTimeDisabled
              ? 'timeTest'
              : 'timeTestFuture'
          "
          v-model="eventData.time"
          :inputValue="eventData.time"
          inputType="time"
          :inputRulesData="eventData.date"
          :disabled="
            eventData.fullDay || (startTimeDisabled && eventData.timeType !== 2)
          "
        />
        <InputField
          :label="$t('eventForm.offerTime.endTime')"
          icon="access_time"
          :rules="
            eventData.fullDay
              ? 'None'
              : eventData.timeType == 0
              ? 'timeTestMore'
              : 'timeTest'
          "
          v-model="eventData.endTime"
          :inputValue="eventData.endTime"
          inputType="time"
          :inputRulesData="eventData.time"
          :disabled="eventData.fullDay"
        />
        <v-row class="pa-0">
          <v-col cols="auto" class="py-0">
            <v-checkbox
              class="ma-0 mb-10"
              v-model="eventData.fullDay"
              :label="$t('eventForm.offerTime.allDay')"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </div>
      <v-divider class="mb-8"></v-divider>
      <v-row>
        <v-col cols="auto" class="py-0">
          <v-checkbox
            v-model="eventData.isProtected"
            :label="$t('offer.protected')"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row class="mb-1"
        ><v-col cols="12" md="8" class="pl-10">
          <span>{{ $t("eventForm.offerTime.protectedTooltip") }}</span>
        </v-col></v-row
      >
      <v-btn color="primary" class="mx-0 mt-3" type="submit">{{
        submitText
      }}</v-btn>
    </v-form>
  </div>
</template>

<script>
import utilitiesMixin from "@/mixins/utilitiesMixin.js";
import InputField from "@/components/partials/InputField";
import Tag from "@/components/partials/Tag";
import WeekdaysDisplay from "@/components/partials/WeekdaysDisplay";
import InputGeosearch from "@/components/partials/InputGeosearch";

export default {
  name: "EventForm",
  components: { InputField, Tag, InputGeosearch, WeekdaysDisplay },
  mixins: [utilitiesMixin],
  props: {
    inputEvent: {
      type: Object,
      default: null,
    },
    mode: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      customTags: false,
      everyDay: false,
      autoCompleteRegion: "",
      eventType: "",
      error: "",
      startTimeDisabled: false,
      startDateDisabled: false,
      eventData: {
        //Základné informácie
        title: null,
        desc: null,
        tags: [],
        demand: null,
        //Miesto ponuky
        place: null,
        coords: null,
        region: null,
        //Čas ponuky
        timeType: 0,
        date: null,
        endDate: null,
        weekdays: [],
        time: null,
        endTime: null,
        fullDay: false,
        //Chránená ponuka
        isProtected: false,
      },
    };
  },
  computed: {
    submitText: function () {
      switch (this.mode) {
        case "create":
          return this.$t("eventForm.offerTime.create");
        case "change":
          return this.$t("eventForm.offerTime.change");
        default:
          return this.$t("eventForm.offerTime.submit");
      }
    },
    eventPresets() {
      return Object.keys(this.$store.state.eventPresets).map((key) => ({
        text: this.$t(`eventPresets.${key}.name`),
        value: key,
      }));
    },
  },
  methods: {
    everyDayClick() {
      if (this.everyDay) {
        this.eventData.weekdays = [
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
          "sunday",
        ];
      } else {
        this.eventData.weekdays = [];
      }
    },
    processAutoCompleteData(data) {
      if (typeof data === "object") {
        this.autoCompleteRegion = data.region;
        this.eventData.place = data.name;
        this.eventData.coords = data.location;

        const regions = Object.keys(this.$store.state.regions);
        
        const bmatch = this.findBestMatch(data.region, regions);
        
        if (bmatch.bestMatch && bmatch.bestMatch.rating > 0.6) {
          this.eventData.region = regions[bmatch.bestMatchIndex];
        }
      } else {
        this.eventData.place = data;
      }
    },
    validateForm() {
      return (
        this.$refs.createForm.validate() &&
        (this.eventData.timeType != 2 ||
          this.parseWeekdays(this.eventData.weekdays) != "0000000")
      );
    },
    submitClick() {
      if (this.validateForm()) {
        this.$emit("submit", this.eventData);
      } else {
        this.error = this.$t("formError");
        window.scrollTo(0, 0);
      }
    },
  },
  watch: {
    eventType: function () {
      if (this.eventType == "other") {
        this.eventData.tags = [];
        this.customTags = true;
      } else {
        this.customTags = false;
        this.eventData.tags = this.$store.state.eventPresets[this.eventType];
      }
    },
    "eventData.weekdays": function () {
      if (this.eventData.weekdays.length < 7) {
        this.everyDay = false;
      }
    },
  },
  created() {
    if (this.inputEvent) {
      this.eventData = JSON.parse(JSON.stringify(this.inputEvent));
    }
    if (this.mode == "change") {
      this.customTags = true;
      this.startTimeDisabled = !this.timeFuture(
        this.eventData.date,
        this.eventData.time
      );
      this.startDateDisabled = !this.timeFuture(
        this.eventData.date,
        this.eventData.time || "00:00"
      );
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
